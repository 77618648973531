@use "~@blueprintjs/core/lib/scss/variables.scss";

.loginDialogElement {
    padding-top: 10px;
}

.label {
    margin-bottom: 10px;
    font-weight: 500;
}

.footer {
    margin-top: -10px;
}

.rememberMeFooter {
    margin-top: 25px;
}

.authButtonContainer {
    width: 100%;
    margin-bottom: 20px;
    flex-direction: column;
    text-align: center;
}

.authButton {
    height: 40px;
    width: 100%;
    margin-top: 10px;

    &:first-child {
        margin-left: 0;
    }
}

.authDividerContainer {
    text-align: center;
    overflow: hidden;
    margin-bottom: 10px;
    &:before {
        background-color: rgba(255, 255, 255, 0.3);
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 50%;
        right: 0.5em;
        margin-left: -50%;
    }
    &:after {
        background-color: rgba(255, 255, 255, 0.3);
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 50%;
        left: 0.5em;
        margin-right: -50%;
    }
}
