@use "sass:color";
@use "~@blueprintjs/core/lib/scss/variables.scss" as scss-variables;
@use "variables.scss";

.bp3-dark {
    .authxTabs {
        .bp3-tab-list {
            position: fixed;
            top: variables.$header-height;
            height: variables.$tabs-height;
            width: 100vw;
            display: flex;
            flex-direction: row;
            align-items: center;
            background: scss-variables.$dark-gray4;
            z-index: 10;
        }

        .bp3-tab {
            color: scss-variables.$gray3;
            height: variables.$tabs-height;
            align-items: center;
            display: flex;
            padding: 0 30px;
            margin: 0;
            box-shadow: inset -1px 0 0 color.adjust(scss-variables.$gray1, $alpha: -0.65) !important;
            outline: none;
            user-select: none;
            transition: color 0.2s linear;
            font-weight: 500;

            &:hover {
                color: scss-variables.$white !important;
            }

            &[aria-selected="true"] {
                color: scss-variables.$white;
                background-color: scss-variables.$dark-gray3 !important;
            }
        }

        .bp3-tab-indicator-wrapper {
            display: none;
        }
    }

    table.authxTable {
        width: 100%;
        table-layout: fixed;
        color: scss-variables.$red3;

        &.bp3-html-table td {
            vertical-align: middle;
            padding: 0 10px;
            height: 40px;
        }

        &.bp3-html-table th.authxAlignRight {
            text-align: right;
            padding-right: 20px;
        }
    }
}

.authxDetailTooltip {
    .bp3-popover-content {
        padding: 10px;
        background-color: scss-variables.$dark-gray3;
    }

    .bp3-popover-arrow-fill,
    .bp3-popover-arrow-border {
        fill: scss-variables.$dark-gray3;
    }
}

.authxDrawerList {
    .bp3-menu-item {
        border-radius: 3px;


        &.bp3-intent-primary.bp3-active,
        &:active {
            background-color: rgba(138, 155, 168, 0.15);
        }
    }
}
