@use "~@blueprintjs/core/lib/scss/variables.scss" as scss-variables;
@use "../common/variables.scss";

.header {
    position: fixed;
    width: 100vw;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: scss-variables.$dark-gray5;
    padding: 0 variables.$padding;
    height: variables.$header-height;
    z-index: 20;
}

.logo {
    height: 25px;
    margin-right: 10px;
    filter: brightness(2);
}

.title {
    font-weight: 500;
    color: scss-variables.$light-gray3;
    font-size: 16px;
}


